<template>
  <div ref="radar" style="width:700px;height:600px;box-sizing: border-box"></div>
</template>

<script>
  import echarts from 'echarts';

  export default {
    name: 'Radar',
    mounted() {
      const chart = echarts.init(this.$refs.radar, 'mui');
      const option = {
        tooltip: {},
        legend: {
          data: ['预算分配', '实际开销'],
        },
        radar: {
          // shape: 'circle',
          name: {
            textStyle: {
              color: '#fff',
              backgroundColor: '#999',
              borderRadius: 3,
              padding: [8, 8],
            },
          },
          indicator: [
            { name: '销售', max: 6500 },
            { name: '管理', max: 16000 },
            { name: '信息技术', max: 30000 },
            { name: '客服', max: 38000 },
            { name: '研发', max: 52000 },
            { name: '市场', max: 25000 },
          ],
        },
        series: [{
          name: '预算 vs 开销（Budget vs spending）',
          type: 'radar',
          // areaStyle: {normal: {}},
          data: [
            {
              value: [4300, 10000, 28000, 35000, 50000, 19000],
              name: '预算分配',
            },
            {
              value: [5000, 14000, 28000, 31000, 42000, 21000],
              name: '实际开销',
            },
          ],
        }],
      };

      chart.setOption(option);
    },
  };
</script>

<style scoped>

</style>
