<template>
  <div ref="funnel" style="width:560px;height:600px;box-sizing: border-box"></div>
</template>

<script>
  import echarts from 'echarts';

  export default {
    name: 'Funnel',
    mounted() {
      const chart = echarts.init(this.$refs.funnel, 'mui');

      const option = {
        tooltip: {
          trigger: 'item',
          formatter: "{a} <br/>{b} : {c}%"
        },
        legend: {
          data: ['展现','点击','访问','咨询','订单']
        },

        series: [
          {
            name:'漏斗图',
            type:'funnel',
            left: '10%',
            top: 60,
            //x2: 80,
            bottom: 60,
            width: '80%',
            // height: {totalHeight} - y - y2,
            min: 0,
            max: 100,
            minSize: '0%',
            maxSize: '100%',
            sort: 'descending',
            gap: 2,
            label: {
              show: true,
              position: 'inside'
            },
            labelLine: {
              length: 10,
              lineStyle: {
                width: 1,
                type: 'solid'
              }
            },
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 1
            },
            emphasis: {
              label: {
                fontSize: 20
              }
            },
            data: [
              {value: 60, name: '访问'},
              {value: 40, name: '咨询'},
              {value: 20, name: '订单'},
              {value: 80, name: '点击'},
              {value: 100, name: '展现'}
            ]
          }
        ]
      };

      chart.setOption(option);

    }
  };
</script>

<style scoped>

</style>
